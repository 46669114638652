/* eslint-disable react/no-unescaped-entities */
import * as React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const H1 = styled.h1`
  font-size: calc(2rem + 3vw);

  @media only screen and (min-width: 1024px) {
    span {
      display: block;
    }
  }
`;

const NotFound = styled.div`
  display: grid;

  h1 {
    font-weight: 800;
    line-height: 100%;
    margin: 10px 0 25px;
    text-align: center;
  }

  .notFound__desc {
    margin: 0 0 40px;

    span {
      display: block;
    }
  }

  .notFound__content {
    text-align: center;
  }

  .icon-circle {
    transition: background 200ms ease-in;
    background: #5dd882;
    border-radius: 100%;
    display: inline-block;
    height: 40px;
    margin-right: 20px;
    line-height: 40px;
    text-align: center;
    width: 40px;
  }

  svg {
    path {
      transition: background 200ms ease-in;
      fill: white;
    }
  }

  .go-back {
    font-weight: 600;
    line-height: 150%;
    border: 0;

    &:hover,
    &:focus {
      .icon-circle {
        background: #fff;
      }

      svg {
        path {
          fill: #5dd882;
        }
      }
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
  }

  @media only screen and (min-width: 1024px) {
    align-items: center;
    grid-template-columns: 1fr 1fr;

    h1 {
      margin-bottom: 35px;
      text-align: left;
    }

    .notFound__content {
      text-align: left;
    }

    .notFound__desc {
      margin: 0 0 90px;
    }
  }
`;

const NotFoundPage = ({ data }) => (
  <>
    <Helmet
      htmlAttributes={{
        lang: 'pl',
      }}
      bodyAttributes={{
        class: 'theme-black',
      }}
    >
      <title>Przykro nam, ta strona nie istnieje</title>
      <meta name="description" content="Ta strona nie istnieje" />
      <link rel="canonical" href="https://monety.olsztyn.pl/404/" />
    </Helmet>
    <main>
      <NotFound>
        <div className="notFound__content">
          <H1>
            <span>Houston, </span>
            <span>we&nbsp;have </span>
            <span>a&nbsp;problem!</span>
          </H1>
          <p className="notFound__desc">
            <span>
              <strong>Error 404</strong>
              {' '}
              - the page you are looking for
            </span>
            <span>
              doesn't exist 😢
            </span>
          </p>
          <p>
            <Link to="/" className="go-back">
              <span className="icon-circle">
                <svg viewBox="0 0 20 13.3" style={{ enableBackground: 'new 0 0 20 13.3' }} width="20">
                  <path d="M20,6.7c0-0.4-0.2-0.8-0.5-1l-5.4-5.4c-0.4-0.4-1.1-0.4-1.6,0c-0.4,0.4-0.4,1.1,0,1.6l3.7,3.7H1.1
                    C0.4,5.6,0,6,0,6.7s0.4,1.1,1.1,1.1h15.1l-3.7,3.7c-0.4,0.4-0.4,1.1,0,1.6c0.2,0.2,0.4,0.3,0.8,0.3s0.6-0.1,0.8-0.3l5.4-5.4
                    C19.8,7.5,20,7.1,20,6.7z"
                  />
                </svg>
              </span>
              Take me home
            </Link>
          </p>
        </div>

        <div className="notFound__image">
          <GatsbyImage
            image={getImage(data.moon)}
            alt="Man in the Moon"
            loading="eager"
          />
        </div>
      </NotFound>
    </main>
  </>
);

export const query = graphql`
  {
    moon: file(relativePath: {regex: "/moon/"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          placeholder: DOMINANT_COLOR
          width: 700
        )
      }
    }
  }
`;

export default NotFoundPage;
